import IndexLayout from '../layouts';
import Wrapper from '../components/Wrapper';
import SiteNav from '../components/header/SiteNav';
import { SiteHeader, outer, inner, SiteMain } from '../styles/shared';
import * as React from 'react';
import { css } from '@emotion/core';

import { PostFullHeader, PostFullTitle, NoImage, PostFull } from '../templates/post';
import { PostFullContent } from '../components/PostContent';
import Footer from '../components/Footer';
import Helmet from 'react-helmet';

const PageTemplate = css`
  .site-main {
    background: #fff;
    padding-bottom: 4vw;
  }
`;

const About: React.FC = () => (
  <IndexLayout>
    <Helmet>
      <title>About</title>
    </Helmet>
    <Wrapper css={PageTemplate}>
      <header css={[outer, SiteHeader]}>
        <div css={inner}>
          <SiteNav />
        </div>
      </header>
      <main id="site-main" className="site-main" css={[SiteMain, outer]}>
        <article className="post page" css={[PostFull, NoImage]}>
          <PostFullHeader>
            <PostFullTitle>About</PostFullTitle>
          </PostFullHeader>

          <PostFullContent className="post-full-content">
            <div className="post-content">
              <p>
                Hi, my name is
                Ankit Kamboj, I build things for the web.
                Im a software engineer based in Gurugram, India specializing
                in building (and occasionally designing) exceptional,
                high-quality websites and applications.
              </p>
              <p>
                Professional software engineer based in Gurugram,
                India who enjoys building things that live on the internet.
                I develop exceptional websites and web apps that provide intuitive
                pixel-perfect user interfaces with efficient and modern backends.
                Shortly after graduating from Kurukshetra University,
                I joined the engineering team at Vernuso where I work on a wide variety
                of interesting and meaningful projects on a daily basis.

                Here are a few technologies I have been working with recently:

                  JavaScript (ES6+)
                  HTML & (S)CSS
                  React
                  Vue
                  Node.js
                  Express
                  GraphQL
                  NativeScript
              </p>

              <p>
                Get In Touch
                Although Im not currently looking for freelance opportunities,
                my inbox is always open. Whether for a potential project or
                just to say hi, I will try my best to answer your email!
                Reach me at - ankitkamboj124@gmail.com
              </p>
            </div>
          </PostFullContent>
        </article>
      </main>
      <Footer />
    </Wrapper>
  </IndexLayout>
);

export default About;
